
.mat-table {
  td:not(:last-child) {
    padding-right: 16px;
  }
}

.mat-column-actions {
  text-align: right;
}
