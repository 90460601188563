// generated using: https://material.io/design/typography/the-type-system.html#type-scale

.mat-typography {
  .font-headline-1 {
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    font-size: 96px;
    letter-spacing: -1.5px;
    line-height: normal;
  }

  .font-headline-2 {
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    font-size: 60px;
    letter-spacing: -0.5px;
    line-height: normal;
  }

  .font-headline-3 {
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    font-size: 48px;
    letter-spacing: 0;
    line-height: normal;
  }

  .font-headline-4 {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 34px;
    letter-spacing: 0.25px;
    line-height: normal;
  }

  .font-headline-5 {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 24px;
    letter-spacing: 0;
    line-height: normal;
  }

  .font-headline-6 {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 20px;
    letter-spacing: 0.15px;
    line-height: normal;
  }

  .font-subtitle-1 {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.15px;
    line-height: normal;
  }

  .font-subtitle-2 {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.1px;
    line-height: normal;
  }

  .font-body-1 {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.5px;
    line-height: normal;
  }

  .font-body-2 {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.25px;
    line-height: normal;
  }

  .font-button {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1.25px;
    line-height: normal;
  }

  .font-caption {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 0.4px;
    line-height: normal;
  }

  .font-overline {
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 10px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    line-height: normal;
  }
}
