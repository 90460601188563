
$padding-small: 16px;
.ps {
  padding: $padding-small;
}

.pst {
  padding-top: $padding-small !important;
}

.psr {
  padding-right: $padding-small !important;
}

.psb {
  padding-bottom: $padding-small !important;
}

.psl {
  padding-left: $padding-small !important;
}

$padding-medium: 32px;
.pm {
  padding: $padding-medium;
}

.pmt {
  padding-top: $padding-medium !important;
}

.pmr {
  padding-right: $padding-medium !important;
}

.pmb {
  padding-bottom: $padding-medium !important;
}

.pml {
  padding-left: $padding-medium !important;
}


.m0 {
  margin: 0 !important;
}

.m0t {
  margin-top: 0 !important;
}

.m0r {
  margin-right: 0 !important;
}

.m0b {
  margin-bottom: 0 !important;
}

.m0l {
  margin-left: 0 !important;
}


.p0 {
  padding: 0 !important;
}

.p0t {
  padding-top: 0 !important;
}

.p0r {
  padding-right: 0 !important;
}

.p0b {
  padding-bottom: 0 !important;
}

.p0l {
  padding-left: 0 !important;
}

$margin-half: 8px;
.mh {
  margin: $margin-half;
}

.mht {
  margin-top: $margin-half !important;
}

.mhr {
  margin-right: $margin-half !important;
}

.mhb {
  margin-bottom: $margin-half !important;
}

.mhl {
  margin-left: $margin-half !important;
}

$margin-small: 16px;
.ms {
  margin: $margin-small;
}

.mst {
  margin-top: $margin-small !important;
}

.msr {
  margin-right: $margin-small !important;
}

.msb {
  margin-bottom: $margin-small !important;
}

.msl {
  margin-left: $margin-small !important;
}

$margin-medium: 32px;
.mm {
  margin: $margin-medium;
}

.mmt {
  margin-top: $margin-medium !important;
}

.mmr {
  margin-right: $margin-medium !important;
}

.mmb {
  margin-bottom: $margin-medium !important;
}

.mml {
  margin-left: $margin-medium !important;
}
