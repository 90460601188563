mat-chip.chip-no-focus {
  &:focus:after {
    display: none;
  }
}

mat-chip.chip-no-hover {
  &:hover:after {
    display: none;
  }
}


mat-chip.chip-small {
  font-size: 0.9em;
  padding: 4px 8px;
  min-height: 22px;
}
