$scrollbarColor: #c5c5c5;
$scrollbarBg: rgba(0,0,0,0);

/* The emerging W3C standard
   that is currently Firefox-only */
* {
  scrollbar-width: thin;
  scrollbar-color: $scrollbarColor $scrollbarBg;
}

/* Works on Chrome/Edge/Safari */
*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
*::-webkit-scrollbar-track {
  background: $scrollbarBg;
}
*::-webkit-scrollbar-thumb {
  background-color: $scrollbarColor;
  border-radius: 20px;
  border: 4px solid $scrollbarBg;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
}
::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}
::-webkit-scrollbar-corner {
  background-color: transparent;
}
