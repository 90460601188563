@use '~@angular/material' as mat;
@import "scss/theme";
@import "~@angular/flex-layout/mq";

@import "scss/component";
@import "scss/scrollbar";
@import "scss/typography";
@import "scss/dialogs";

$typography-config: mat.define-typography-config(
  /*$headline: mat.define-typography-level(
    $font-size: 24px,
    $line-height: 32px,
    $font-weight: 500,
  ),
  /*$title: mat.define-typography-level(
    $font-size: 20px,
    $line-height: 32px,
    $font-weight: 500,
  ),*/
  /*$subheading-2: mat.define-typography-level(
    $font-size: 16px,
    $line-height: 28px,
    $font-weight: 500,
      //$font-family: 'Roboto, "Helvetica Neue", sans-serif',
    $font-family: 'Roboto, "Helvetica Neue", sans-serif',
    $letter-spacing: normal,
  ),*/
  $body-1: mat.define-typography-level(
    $font-size: 17px,
    $line-height: 28px,
    $font-weight: 400,
  ),
  /*$button: mat.define-typography-level(
    $font-size: 16px,
    $line-height: 26px,
    $font-weight: 400,
    $letter-spacing: 0.2em,
  ),*/
);

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core($typography-config);


// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($appTheme);

/* You can add global styles to this file, and also import other style files */


html {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  min-height: 100%;
  margin: 0;
  padding: 0;
  display: grid;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}


router-outlet {
  display: none;
}


.card {
  display: block;
  background-color: white;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.15);
}


mat-form-field.mat-form-field-appearance-outline.dense {
  .mat-form-field-wrapper {
    padding: 0;
    margin: 0;

    .mat-form-field-infix {
      padding: 0.9em 0 0.9em 0;
      border-top: 0.34375em solid transparent;
    }
  }
}


.main-with-aside {
  display: grid;
  grid-template-columns: 2fr 300px;
  grid-template-areas: 'main aside';
  grid-gap: 42px;
  align-items: flex-start;

  @include layout-bp(lt-sm) {
    grid-template-columns: 1fr;
    grid-auto-flow: row;
    grid-template-areas: unset;
  }

  .main {
    grid-area: main;

    @include layout-bp(lt-sm) {
      grid-area: unset;
    }
  }

  .aside {
    grid-area: aside;

    @include layout-bp(lt-sm) {
      grid-area: unset;
    }
  }
}


.grid {
  display: grid;
}


$bodyBg: #F0F2F5;
$sideBg: #ffffff;
$toolbarBg: #ffffff;
$logoBg: #ffffff;

mat-sidenav.mat-drawer {
  background-color: $sideBg;
  border-right: 1px solid #E5E5E5;
}

mat-toolbar.mat-toolbar {
  background-color: $toolbarBg;
}

mat-sidenav-content.mat-drawer-content {
  background-color: $bodyBg;
}

main {
}


.mat-typography h1, .mat-typography h2, .mat-typography h3, .mat-typography h4, .mat-typography h5, .mat-typography h6 {
  margin-bottom: unset;
}

.main-sidenav .mat-drawer-inner-container {
  display: grid;
  grid-template-rows: max-content minmax(max-content, 1fr) max-content;
}


button {
  cursor: pointer;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}


.line-below-header {
  @include line-below-header;
}

@import "scss/ps-ms";
@import "scss/notify";
@import "scss/mat-table";
@import "scss/mat-chips";
@import "scss/mat-drag-drop";


.link {
  color: $appPrimaryColor;
  background-color: transparent;
  outline: none;
  border: none;
  text-decoration: underline;
  font-size: 1em;
  display: inline-block;
  padding: 0;
  margin: 0;
}

mat-form-field.no-label {
  .mat-form-field-infix {
    border-top-width: 4px;
  }
}


.icon-container {
  display: inline-block;
  width: 24px;
  height: 24px;
}

.mat-progress-spinner, .mat-spinner {
  &.green {
    circle {
      stroke: $appGreenColor;
    }
  }
}

.mat-icon {
  &.green {
    color: $appGreenColor;
  }

  &.red {
    color: $appPrimaryColor;
  }

  &.grey {
    color: #5e5e5e;
  }

  &.lightgrey {
    color: #aaaaaa;
  }
}

mat-form-field.autosize {
  .mat-form-field-infix {
    width: auto;
  }
}

.mat-button-toggle-checked {
  text-decoration: underline;
  background-color: #e5e5e5;

  .mat-button-toggle-label-content {
    text-decoration: underline;
  }
}

.mat-button-toggle-label-content {
  // set height of toggle buttons to regular button height
  line-height: 38px !important;
}


.page-header {
  padding: 32px 32px 0 32px;
  @include layout-bp(lt-md) {
    padding: 16px 16px 0 16px;
  }
}

.content-page {
  display: grid;
  padding: 32px;
  box-sizing: border-box;
  max-width: 1200px;

  @include layout-bp(lt-md) {
    padding: 16px;
  }
}

app-frame-in.collapsed {
  .content-page {
    max-width: 1200px + (255px - 72px);
  }
}

b, strong {
  font-weight: 500;
}

.tag-small {
  display: inline-block;
  color: #5e5e5e;
  padding: 4px 6px;
  box-sizing: border-box;
  font-size: 0.8em;
  line-height: 0.8em;
  height: 1.6em;
  border: 1px solid #c5c5c5;
  border-radius: 6px;
}

.tag-large {
  display: inline-block;
  color: #5e5e5e;
  padding: 4px 8px;
  box-sizing: border-box;
  font-size: 1em;
  line-height: 1em;
  border: 1px solid #c5c5c5;
  border-radius: 6px;
  height: 1.68em;
}

.label-with-value {

  h3, h4 {
    font-size: 0.9em;
    line-height: 1em;
    font-weight: 500;
    color: #5e5e5e;
  }

  & > div {

  }
}

hr {
  display: block;
  width: 100%;
  box-sizing: border-box;
  border-top: 1px solid #e5e5e5;
  border-left: none;
  border-right: none;
  border-bottom: none;
  outline: none;
  height: 1px;
}


.mat-checkbox-label {
  white-space: normal; // enable automatic linebreaks for checkboxes
}

.mat-checkbox.mat-accent, .mat-checkbox.mat-primary {
  .mat-checkbox-inner-container {
    margin: 3.5px 8px auto 0; // align checkboxes to the start of the text
  }
}


:not(.mat-chip).chip-small {
  display: inline-block;
  line-height: 130%;
  padding: 3px 10px 2px 10px;
  border-radius: 32px;
  font-size: 0.9em;
  background-color: #e5e5e5;

  &.blue {
    background-color: #c7d6fd;
    color: #020f2f;
  }

  &.yellow {
    background-color: #fae8aa;
    color: #493703;
  }

  &.red {
    background-color: #ecbbc1;
    color: #2b0202;
  }

  &.green {
    background-color: #abe2b9;
    color: #032b0f;
  }
}

.article .content {
  img {
    max-width: 100% !important;
  }

}

.mat-horizontal-stepper-header-container {
  margin-bottom: 16px;
}


.button-with-icon {
  .mat-button-wrapper {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    grid-gap: 8px;

    .mat-icon {
      opacity: 0.8;
      transform: scale(0.9);
    }
  }
}


mat-form-field.declaration {
  .mat-form-field-wrapper {
    padding-left: 14em;
  }

  .mat-form-field-label-wrapper {
    left: -14.75em;
  }

  .mat-form-field-label {
    transform: none !important;
  }

  .mat-form-field-outline-gap {
    display: none;
  }

  .mat-form-field-infix {
    border-top-width: 0.3em;
  }
}

mat-button[matdialogclose] {
  cursor: pointer;
}

.block {
  display: block;
}

.mouse-pointer {
  cursor: pointer;
}

.mat-icon.icon-small {
  $size: 16px;
  font-size: $size;
  height: $size;
  width: $size;
  vertical-align: top;
}


.info-line {
  color: $appBlueColor;
}

