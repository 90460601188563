.mat-snack-bar-container.file-download-snackbar {
  min-width: 100px;
}

.mat-snack-bar-container {
  &.success {
    background-color: #28a745;
    color: #fff;
  }

  &.error {
    background-color: #dc3545;
    color: #fff;
  }

  &.info {
    background-color: #007bff;
    color: #fff;
  }

  &.warn {
    background-color: #ffc107;
    color: #000;
  }
}

