@import '~@angular/flex-layout/mq';
@import "theme";


@mixin page {
  display: grid;
  padding: 32px;
  box-sizing: border-box;
}


@mixin card {
  background-color: white;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.15);
}

@mixin poppins {
  font-family: 'Poppins', sans-serif;
}


@mixin roboto {
  font-family: 'Roboto', sans-serif;
}


@mixin line-below-header {
  border-bottom: 1px solid #d4d3d3;
  margin-bottom: 24px;
  padding-bottom: 16px;
}
