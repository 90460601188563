// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '~@angular/material' as mat;
@import '~@angular/material/theming';
// Plus imports for other components in your app.


// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$appPrimaryPallet: mat.define-palette((
  50 : #f7e2e4,
  100 : #ebb6bd,
  200 : #de8691,
  300 : #d15664,
  400 : #c73143,
  500 : #bd0d22,
  600 : #b70b1e,
  700 : #ae0919,
  800 : #a60714,
  900 : #98030c,
  A100 : #ffc3c5,
  A200 : #ff9094,
  A400 : #ff5d62,
  A700 : #ff4449,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #ffffff,
  )
));

$appAccentPallet: mat.define-palette((
  50 : #fdf8e5,
  100 : #faeebe,
  200 : #f7e393,
  300 : #f4d867,
  400 : #f1cf47,
  500 : #efc726,
  600 : #edc122,
  700 : #ebba1c,
  800 : #e8b317,
  900 : #e4a60d,
  A100 : #ffffff,
  A200 : #fff4dc,
  A400 : #ffe4a9,
  A700 : #ffdc90,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
));

$appPrimaryColor: mat.get-color-from-palette($appPrimaryPallet, 500);
$appAccentColor: mat.get-color-from-palette($appAccentPallet, 500);

// The warn palette is optional (defaults to red).
$appWarnPallet: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$appTheme: mat.define-light-theme((
  color: (
    primary: $appPrimaryPallet,
    accent: $appAccentPallet,
    warn: $appWarnPallet,
  )
));


$appGreenColor: #2AB830;
$appBlueColor: #3970FE;
