.dialog-header {
  display: flex;
  align-items: center;

  h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: 0;
    flex: 1 0 auto;
  }
}

.dialog-unpad-left {
  margin-left: -24px;
}

.dialog-unpad-right {
  margin-right: -24px;
}

.dialog-unpad-top {
  margin-top: -24px;
}

.dialog-unpad-bottom {
  margin-bottom: -24px;
}
